table {
  border-spacing: 0;
  border: 1px solid #ededed;
  margin-top: 5px;
}
table tr:last-child td {
  border-bottom: 0;
}
table th {
  background-color: rgb(31, 143, 1);
  color: white;
}
table th,
table td {
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #f9fad3;
}

table th::before {
  position: absolute;
  right: 5px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

table th.sort-asc::before {
  border-bottom: 10px solid white;
}
table th.sort-desc::before {
  border-top: 10px solid white;
}
input {
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid #aaa;
  box-shadow: none;
  background-color: white;
}

.divLoader {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

.bg-list-button {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 69, 44, var(--tw-bg-opacity));
}
